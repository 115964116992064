
<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="31" height="35">

<g transform="matrix(0.10000001 0 0 0.10000001 0.20000002 -0)">
    <path d="M184.227 15C 184.227 6.7157288 190.94273 0 199.227 0L199.227 0L291 0C 299.28427 0 306 6.7157288 306 15L306 15L306 335C 306 343.28427 299.28427 350 291 350L291 350L199.227 350C 190.94273 350 184.227 343.28427 184.227 335L184.227 335L184.227 15z" stroke="none" :fill="color" fill-rule="nonzero" />
    <path d="M0 15C 0 6.7157288 6.7157288 0 15 0L15 0L106.773 0C 115.057274 0 121.773 6.7157288 121.773 15L121.773 15L121.773 335C 121.773 343.28427 115.057274 350 106.773 350L106.773 350L15 350C 6.7157288 350 0 343.28427 0 335L0 335L0 15z" stroke="none" :fill="color" fill-rule="nonzero" />
</g>
</svg>
</template>

<script>
export default {
  name: 'PauseMinimal',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>